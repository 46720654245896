import React, { FC, useEffect, useState } from 'react';
import { ChartData, UserData } from '..';
import quizSvgs from 'utils/constants/quizSvgs';
import SliderChart from './MgSliderChart';
import {
  getElement,
  getElementSvgName,
  getGenderSvgName,
} from 'utils/constants/zodiac';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

interface AstrologicalProfileProps {
  userData: UserData;
  chartData: ChartData[];
  renderSvgImage: (zodiac: keyof typeof quizSvgs) => React.JSX.Element;
  getZodiacSvgName: (zodiac: string) => keyof typeof quizSvgs;
}

const Title = styled.h1`
  color: #000;
  text-align: center;
  font-feature-settings: "liga" off;
  font-family: "Afacad", sans-serif;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 115%;
  margin: 1.5rem 0;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 28.75rem;
  min-width: 21.4375rem;
  width: 100%;

  svg {
    width: 9.375rem;
    height: 9.375rem;

    @media ${tablet} {
      width: 7.875rem;
      height: 7.875rem;
    }
  }
`;

const ZodiacTitle = styled.p`
  text-align: center;
  font-family: "Afacad", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 115%;
  color: ${({ theme }) => theme.colors.mgPrimary100};
  margin-bottom: 1rem;
  text-transform: capitalize;
`;

const ProfileZodiacTitle = styled(ZodiacTitle)`
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0.75rem;

  @media ${tablet} {
    font-size: 1.5rem;
    margin-top: 0.5rem;
  }
`;

const CompatibilityCard = styled.div`
  padding: 2rem 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.lighterGrey};
  display: flex;
  flex-direction: column;
  max-width: 28.75rem;
  min-width: 21.4375rem;
  width: 100%;
`;

const ZodiacContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;

  svg {
    width: 5.9375rem;
    height: 5.9375rem;
    margin-bottom: 0.5rem;
  }

  span {
    font-family: "Afacad", sans-serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    color: ${({ theme }) => theme.colors.mgPrimary100};
    /* padding-bottom: 31px; */
    height: 5.9375rem;
    display: flex;
    align-items: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: grid;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  gap: 0.12rem;
`;

const InfoLabel = styled.p`
  color: ${({ theme }) => theme.colors.mgPrimary75};
  text-align: center;
  font-family: "Afacad", sans-serif;
  font-size: 0.875rem;
  line-height: normal;
`;

const Info = styled.p`
  font-size: 1rem;
  line-height: 1.125rem;
  text-transform: capitalize;
  max-width: 5.9375rem;
`;

const Divider = styled.div`
  width: 1px;
  height: 12rem;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
`;

const OrnamentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const InfoColumn = styled(Column)`
  gap: 0.75rem;
`;

const AstroCardWrapper = styled.div`
  display: grid;
  gap: 0.5rem;
  width: 100%;

  @media ${tablet} {
    gap: 0.25rem;
  }
`;

const AstrologicalCard = styled.div`
  padding: 0.75rem 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  gap: 0.5rem;
  width: 100%;

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  @media ${tablet} {
    padding: 0.5rem 1rem;
  }
`;

const AstrologicalCardLabel = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.mgPrimary75};

  @media ${tablet} {
    font-size: 0.625rem;
  }
`;

const AstrologicalCardText = styled.p`
  font-size: 1.125rem;
  line-height: 1.125rem;
  text-transform: capitalize;

  @media ${tablet} {
    font-size: 1rem;
  }
`;

const AstrologicalProfile: FC<AstrologicalProfileProps> = ({
  userData,
  chartData,
  renderSvgImage,
  getZodiacSvgName,
}) => {
  const renderSvg = (name: string) => {
    if (name) {
      const nameAsKey = name as keyof typeof quizSvgs;

      const Svg = quizSvgs[nameAsKey];
      return <Svg />;
    }
  };

  const [userElement, setUserElement] = useState<string>('');

  useEffect(() => {
    setUserElement(getElement(userData.zodiac));
  }, []);

  return (
    <>
      <Title>Here’s your astrological profile:</Title>
      <ProfileContainer>
        {renderSvgImage(getZodiacSvgName(userData.zodiac))}
        <ProfileZodiacTitle>{userData.zodiac}</ProfileZodiacTitle>
        <AstroCardWrapper>
          <AstrologicalCard>
            {renderSvg(getElementSvgName(userElement) as keyof typeof quizSvgs)}
            <div>
              <AstrologicalCardLabel>Element</AstrologicalCardLabel>
              <AstrologicalCardText>{userElement}</AstrologicalCardText>
            </div>
          </AstrologicalCard>
          <AstrologicalCard>
            {renderSvg('MgCalendar')}
            <div>
              <AstrologicalCardLabel>Date of Birth</AstrologicalCardLabel>
              <AstrologicalCardText>
                {userData.dateOfBirth.toLocaleDateString('en-US')}
              </AstrologicalCardText>
            </div>
          </AstrologicalCard>
          <AstrologicalCard>
            {renderSvg(getGenderSvgName(userData.gender))}
            <div>
              <AstrologicalCardLabel>Gender</AstrologicalCardLabel>
              <AstrologicalCardText>{userData.gender}</AstrologicalCardText>
            </div>
          </AstrologicalCard>
          <AstrologicalCard>
            {renderSvg('MgLocation')}
            <div>
              <AstrologicalCardLabel>Place of Birth</AstrologicalCardLabel>
              <AstrologicalCardText>
                {userData.placeOfBirth}
              </AstrologicalCardText>
            </div>
          </AstrologicalCard>
        </AstroCardWrapper>
      </ProfileContainer>
      <SliderChart data={chartData} />
    </>
  );
};

export default AstrologicalProfile;
