import quizSvgs from './quizSvgs';

export const ZodiacPlural = {
  aries: 'Aries',
  taurus: 'Tauruses',
  gemini: 'Geminis',
  cancer: 'Cancers',
  leo: 'Leos',
  virgo: 'Virgos',
  libra: 'Libras',
  scorpio: 'Scorpios',
  sagittarius: 'Sagittariuses',
  capricorn: 'Capricorns',
  aquarius: 'Aquariuses',
  pisces: 'Pisces',
} as const;

type ZodiacSign = keyof typeof ZODIAC_TO_ELEMENT;
type Element = (typeof ZODIAC_TO_ELEMENT)[ZodiacSign];
type ElementSvgName = (typeof ELEMENT_TO_SVG)[keyof typeof ELEMENT_TO_SVG];
type Gender = keyof typeof GENDER_TO_SVG;
type GenderSvgName = (typeof GENDER_TO_SVG)[Gender];

// Zodiac to Element mapping
export const ZODIAC_TO_ELEMENT = {
  aries: 'fire',
  taurus: 'earth',
  gemini: 'air',
  cancer: 'water',
  leo: 'fire',
  virgo: 'earth',
  libra: 'air',
  scorpio: 'water',
  sagittarius: 'fire',
  capricorn: 'earth',
  aquarius: 'air',
  pisces: 'water',
} as const;

// Zodiac to SVG mapping
export const ZODIAC_TO_SVG = {
  aries: 'MgAriesCard',
  taurus: 'MgTaurusCard',
  gemini: 'MgGeminiCard',
  cancer: 'MgCancerCard',
  leo: 'MgLeoCard',
  virgo: 'MgVirgoCard',
  libra: 'MgLibraCard',
  scorpio: 'MgScorpioCard',
  sagittarius: 'MgSagittariusCard',
  capricorn: 'MgCapricornCard',
  aquarius: 'MgAquariusCard',
  pisces: 'MgPiscesCard',
} as const;

export const ELEMENT_TO_SVG = {
  fire: 'MgFire',
  earth: 'MgEarth',
  air: 'MgAir',
  water: 'MgWater',
} as const;

export const GENDER_TO_SVG = {
  male: 'MgMale',
  female: 'MgFemale',
} as const;

export const getElement = (sign: string): string => {
  const normalizedSign = sign.toLowerCase();
  return ZODIAC_TO_ELEMENT[normalizedSign as ZodiacSign] || '';
};

export const getZodiacSvgName = (sign: string): string => {
  const normalizedSign = sign.toLowerCase();
  return ZODIAC_TO_SVG[normalizedSign as ZodiacSign] || '';
};

export const getElementSvgName = (element: string): string => {
  const normalizedElement = element.toLowerCase();
  return ELEMENT_TO_SVG[normalizedElement as Element] || '';
};

export const getGenderSvgName = (gender: string): string => {
  const normalizedGender = gender.toLowerCase().replace('-', '_');
  return GENDER_TO_SVG[normalizedGender as Gender] || '';
};
