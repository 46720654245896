import React, { FC } from 'react';
import { ChartData, UserData } from '..';
import quizSvgs from 'utils/constants/quizSvgs';
import SliderChart from './MgSliderChart';
import styled from 'styled-components';

interface CompatibilityProfileProps {
  userData: UserData;
  partnerData: UserData;
  chartData: ChartData[];
  renderSvgImage: (zodiac: keyof typeof quizSvgs) => React.JSX.Element;
  getZodiacSvgName: (zodiac: string) => keyof typeof quizSvgs;
  getElement: (zodiac: string) => string;
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CompatibilityCard = styled.div`
  padding: 2rem 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.lighterGrey};
  display: flex;
  flex-direction: column;
  max-width: 28.75rem;
  min-width: 21.4375rem;
  width: 100%;
`;

const Divider = styled.div`
  width: 1px;
  height: 12rem;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
`;

const Info = styled.p`
  font-size: 1rem;
  line-height: 1.125rem;
  text-transform: capitalize;
  max-width: 5.9375rem;
`;

const InfoColumn = styled(Column)`
  gap: 0.75rem;
`;

const InfoLabel = styled.p`
  color: ${({ theme }) => theme.colors.mgPrimary75};
  text-align: center;
  font-family: "Afacad", sans-serif;
  font-size: 0.875rem;
  line-height: normal;
`;

const OrnamentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Row = styled.div`
  display: grid;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  gap: 0.12rem;
`;

const Title = styled.h1`
  color: #000;
  text-align: center;
  font-feature-settings: "liga" off;
  font-family: "Afacad", sans-serif;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 115%;
  margin: 1.5rem 0;
`;

const ZodiacContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;

  svg {
    width: 5.9375rem;
    height: 5.9375rem;
    margin-bottom: 0.5rem;
  }

  span {
    font-family: "Afacad", sans-serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    color: ${({ theme }) => theme.colors.mgPrimary100};
    /* padding-bottom: 31px; */
    height: 5.9375rem;
    display: flex;
    align-items: center;
  }
`;

const ZodiacTitle = styled.p`
  text-align: center;
  font-family: "Afacad", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 115%;
  color: ${({ theme }) => theme.colors.mgPrimary100};
  margin-bottom: 1rem;
  text-transform: capitalize;
`;

const CompatibilityProfile: FC<CompatibilityProfileProps> = ({
  userData,
  partnerData,
  chartData,
  renderSvgImage,
  getZodiacSvgName,
  getElement,
}) => (
  <>
    <Title>Here’s your compatibility profile:</Title>
    <CompatibilityCard>
      <ZodiacContainer>
        <Column>
          {renderSvgImage(getZodiacSvgName(userData.zodiac))}
          <ZodiacTitle>{userData.zodiac}</ZodiacTitle>
          <InfoColumn>
            <Row>
              <InfoLabel>Element</InfoLabel>
              <Info>{getElement(userData.zodiac)}</Info>
            </Row>
            <Row>
              <InfoLabel>Date of Birth</InfoLabel>
              <Info>{userData.dateOfBirth.toLocaleDateString()}</Info>
            </Row>
            <Row>
              <InfoLabel>Gender</InfoLabel>
              <Info>{userData.gender}</Info>
            </Row>
            <Row>
              <InfoLabel>Place of Birth</InfoLabel>
              <Info>{userData.placeOfBirth}</Info>
            </Row>
          </InfoColumn>
        </Column>
        <OrnamentsContainer>
          <span>+</span>
          <Divider />
        </OrnamentsContainer>
        <Column>
          {renderSvgImage(getZodiacSvgName(partnerData.zodiac))}
          <ZodiacTitle>{partnerData.zodiac}</ZodiacTitle>
          <InfoColumn>
            <Row>
              <InfoLabel>Element</InfoLabel>
              <Info>{getElement(partnerData.zodiac)}</Info>
            </Row>
            <Row>
              <InfoLabel>Date of Birth</InfoLabel>
              <Info>{partnerData.dateOfBirth.toLocaleDateString('en-US')}</Info>
            </Row>
            <Row>
              <InfoLabel>Gender</InfoLabel>
              <Info>{partnerData.gender}</Info>
            </Row>
            <Row>
              <InfoLabel>Place of Birth</InfoLabel>
              <Info>{partnerData.placeOfBirth}</Info>
            </Row>
          </InfoColumn>
        </Column>
      </ZodiacContainer>
    </CompatibilityCard>
    <SliderChart data={chartData} />
  </>
);

export default CompatibilityProfile;
