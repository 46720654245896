import React, { FC } from 'react';
import styled from 'styled-components';

export interface ChartData {
  title: string;
  value: number;
}

interface SliderChartProps {
  data: ChartData[];
}

const CompatibilityCard = styled.div`
  padding: 2rem 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.lighterGrey};
  display: flex;
  flex-direction: column;
  max-width: 28.75rem;
  min-width: 21.4375rem;
  width: 100%;
`;

const CompatibilityChartCard = styled(CompatibilityCard)`
  margin-top: 1rem;
  gap: 1rem;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Chart = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  position: relative;
`;

const ChartBar = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  height: 0.5rem;

  &:first-child {
    border-radius: 1rem 0 0 1rem;
  }

  &:last-child {
    border-radius: 0 1rem 1rem 0;
  }
`;

const ChartTitle = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem;
`;

const ChartLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ChartLabel = styled.p`
  font-family: "Afacad", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.mgPrimary50};
`;

const ChartMarker = styled.div<{ value?: number }>`
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid white;
  border-radius: 9999px;
  top: 50%;
  left: ${({ value }) => (value ? `${value}%` : 0)};
  background-color: ${({ theme }) => theme.colors.mgPrimary100};
  transform: translateX(-50%) translateY(-50%);
`;

const SliderChart: FC<SliderChartProps> = ({ data }) => (
  <CompatibilityChartCard>
    {data &&
      data.map(chart => (
        <ChartContainer>
          <ChartTitle>{chart.title}</ChartTitle>
          <Chart>
            <ChartBar bgColor={'#D45148'} />
            <ChartBar bgColor={'#D45148'} />
            <ChartBar bgColor={'#FFD128'} />
            <ChartBar bgColor={'#FFD128'} />
            <ChartBar bgColor={'#FFD128'} />
            <ChartBar bgColor={'#498D27'} />
            <ChartMarker value={chart.value} />
            <ChartBar bgColor={'#498D27'} />
          </Chart>
          <ChartLabelContainer>
            <ChartLabel>Low</ChartLabel>
            <ChartLabel>Medium</ChartLabel>
            <ChartLabel>High</ChartLabel>
          </ChartLabelContainer>
        </ChartContainer>
      ))}
  </CompatibilityChartCard>
);

export default SliderChart;
